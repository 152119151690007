<template>
    <div>
        <v-container>
            <v-card class="pa-6">
                <v-card-title class="text-h5">{{ $translate("routes.UpdatingWinners") }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="buscarGanadores" v-model="formValid">
                        <!-- Selector de programa -->
                        <v-select v-model="selectedProgram" :items="programOptions" label="Selecciona el programa"
                            item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona un programa']" @input="handleProgramChange" />

                        <!-- Selector de Recurrencia -->
                        <v-select v-model="selectedRecurrence" :items="recurrenceOptions"
                            label="Selecciona la recurrencia" item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona una recurrencia']" />

                        <!-- Selector de Año -->
                        <v-select v-model="selectedYear" :items="yearOptions" label="Selecciona el año" outlined
                            required :rules="[v => !!v || 'Por favor selecciona un año']" />

                        <v-btn :disabled="loadingAll || !formValid" color="primary" block type="submit" class="mt-4"
                            background-color="#466be3">
                            Buscar ganadores
                        </v-btn>
                    </v-form>

                    <!-- Tabla de ganadores -->
                    <v-data-table v-if="ganadores.length > 0" v-model="selectedGanadores" :headers="headers"
                        :items="ganadores" :loading="loadingTable" show-select item-key="id" class="mt-4"
                        :items-per-page="50" :footer-props="{
                            'items-per-page-options': [50],
                            'items-per-page-text': 'Filas por página:',
                            'page-text': '{0}-{1} de {2}'
                        }">
                        <!-- Template para la columna de nombre -->
                        <template v-slot:item.nombre="{ item }">
                            <div class="d-flex align-center">
                                <v-avatar size="32" class="mr-2">
                                    <v-img :src="item.user.img" />
                                </v-avatar>
                                <div>
                                    <div>{{ item.user.full_name }}</div>
                                    <div class="caption grey--text">{{ item.cargo }}</div>
                                </div>
                            </div>
                        </template>

                        <!-- Template para acciones adicionales -->
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Ganadores del programa</v-toolbar-title>
                                <v-spacer></v-spacer>

                                <!-- Controles para actualización masiva -->
                                <v-row v-if="selectedGanadores.length > 0" align="center" class="mx-0">
                                    <v-col cols="3" class="pa-2">
                                        <v-select v-model="actualizacionMasiva.programa_id" :items="programOptions"
                                            item-text="nombre" item-value="id" label="Programa" dense outlined
                                            hide-details @change="handleProgramaMasivoChange" />
                                    </v-col>
                                    <v-col cols="3" class="pa-2">
                                        <v-select v-model="actualizacionMasiva.periodo_id"
                                            :items="recurrenceOptionsMasivo" item-text="nombre" item-value="id"
                                            label="Periodo" dense outlined hide-details />
                                    </v-col>
                                    <v-col cols="3" class="pa-2">
                                        <v-select v-model="actualizacionMasiva.vigencia" :items="yearOptions"
                                            label="Año" dense outlined hide-details />
                                    </v-col>
                                    <v-col cols="3" class="pa-2">
                                        <v-btn color="primary" :disabled="!isValidMasiveUpdate"
                                            @click="actualizarSeleccionados">
                                            Actualizar {{ selectedGanadores.length }} seleccionado{{
                                                selectedGanadores.length !== 1 ? 's' : '' }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                    </v-data-table>

                    <!-- Mensaje cuando no hay ganadores -->
                    <v-alert v-else-if="busquedaRealizada" type="info" class="mt-4">
                        No se encontraron ganadores para los criterios seleccionados
                    </v-alert>

                </v-card-text>
            </v-card>
        </v-container>

        <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
            :redirect="message.redirect" @close="message.dialog = false" />

        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                Cargando...
            </v-progress-circular>
        </v-overlay>

        <!-- Diálogo de confirmación para actualización masiva -->
        <v-dialog v-model="dialogConfirmMasivo.show" max-width="400">
            <v-card>
                <v-card-title class="text-h5">Confirmar actualización masiva</v-card-title>
                <v-card-text>
                    <p class="text-subtitle-1 mb-3">¿Estás seguro de que deseas actualizar {{ selectedGanadores.length
                        }}
                        ganador(es)?</p>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Programa:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ getProgramaNombre(actualizacionMasiva.programa_id) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Periodo:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ getPeriodoNombre(actualizacionMasiva.periodo_id) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold">Año:</v-list-item-title>
                                <v-list-item-subtitle class="subtitle-1">
                                    {{ actualizacionMasiva.vigencia }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogConfirmMasivo.show = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="confirmarActualizacionMasiva">
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Api from "@/utils/api";
import Alert from "@/components/Alert.vue";

export default {
    name: 'UpdatingWinners',
    components: {
        Alert,
    },
    data() {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        return {
            loadingAll: false,
            loadingTable: false,
            formValid: false,
            busquedaRealizada: false,
            programOptions: [],
            recurrenceOptions: [],
            selectedProgram: null,
            selectedRecurrence: null,
            selectedYear: null,
            yearOptions: [previousYear, currentYear],
            ganadores: [],
            estadosOptions: [
                { text: 'Activo', value: 'A' },
                { text: 'Inactivo', value: 'I' }
            ],
            headers: [
                { text: 'Seleccionar', value: 'data-table-select' },
                { text: 'ID', value: 'id', sortable: true },
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'País', value: 'user.country' },
                { text: 'Programa', value: 'programa.nombre' },
                { text: 'Periodo', value: 'periodo.nombre' }
            ],
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            },
            token: null,
            selectedGanadores: [],
            periodoMasivo: null,
            actualizacionMasiva: {
                programa_id: null,
                periodo_id: null,
                vigencia: null
            },
            recurrenceOptionsMasivo: [],
            dialogConfirmMasivo: {
                show: false
            }
        };
    },
    computed: {
        isValidMasiveUpdate() {
            return this.actualizacionMasiva.programa_id &&
                this.actualizacionMasiva.periodo_id &&
                this.actualizacionMasiva.vigencia &&
                this.selectedGanadores.length > 0;
        }
    },
    created() {
        this.token = this.$route.params.token;
        if (!this.token) {
            this.token = localStorage.getItem("tokenB64");
        }
        this.getProgramas();
    },
    watch: {
        selectedGanadores(newVal) {
            if (newVal.length === 0) {
                this.actualizacionMasiva = {
                    programa_id: null,
                    periodo_id: null,
                    vigencia: null
                };
            }
        }
    },
    methods: {
        getProgramas() {
            Api.Reconocimientos()
                .getProgramas(this.token)
                .then((res) => res.json())
                .then((response) => {
                    this.programOptions = response.data.reverse();
                    if (this.programOptions.length > 0) {
                        this.updateRecurrenceOptions();
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener programas:', error);
                    this.showAlert("Error", "No se pudieron cargar los programas", "error");
                });
        },

        handleProgramChange() {
            this.updateRecurrenceOptions();
        },

        updateRecurrenceOptions() {
            if (this.selectedProgram && this.programOptions) {
                const selectedProgramData = this.programOptions.find(program => program.id === this.selectedProgram);
                if (selectedProgramData && selectedProgramData.recurrencia) {
                    this.recurrenceOptions = selectedProgramData.recurrencia.periodos;
                    this.selectedRecurrence = null;
                }
            }
        },

        buscarGanadores() {
            this.loadingTable = true;
            this.busquedaRealizada = true;

            const data = {
                programa_id: this.selectedProgram,
                periodo_id: this.selectedRecurrence,
                vigencia: this.selectedYear
            };

            Api.Reconocimientos()
                .getGanadoresPrograma(this.token, JSON.stringify(data))
                .then((res) => res.json())
                .then((response) => {
                    if (response.data && Array.isArray(response.data)) {
                        this.ganadores = response.data;
                    } else {
                        this.ganadores = [];
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener ganadores:', error);
                    this.showAlert("Error", "No se pudieron cargar los ganadores", "error");
                })
                .finally(() => {
                    this.loadingTable = false;
                });
        },

        handleEstadoChange(ganador) {
            this.loadingAll = true;

            const data = {
                programa_id: this.selectedProgram,
                periodo_id: this.selectedRecurrence,
                vigencia: this.selectedYear,
                cedula: ganador.cedula,
                estado: ganador.estado
            };

            Api.Reconocimientos()
                .actualizarGanadores(this.token, JSON.stringify(data))
                .then((res) => res.json())
                .then((response) => {
                    if (response.success) {
                        this.showAlert("Éxito", "Estado actualizado correctamente", "success");

                    } else {
                        throw new Error(response.message || "Error al actualizar el estado");
                    }
                })
                .catch((error) => {
                    console.error('Error al actualizar ganador:', error);
                    this.showAlert("Error", "No se pudo actualizar el estado del ganador", "error");
                    // Revertir el cambio en caso de error
                    ganador.estado = ganador.estado === 'A' ? 'I' : 'A';
                })
                .finally(() => {
                    this.loadingAll = false;
                });
        },

        showAlert(title, message, type) {
            this.message.dialog = true;
            this.message.title = title;
            this.message.sms = message;
            this.message.type = type;
        },

        clearForm() {
            this.selectedProgram = null;
            this.selectedRecurrence = null;
            this.selectedYear = null;
            this.ganadores = [];
            this.busquedaRealizada = false;

            this.$nextTick(() => {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }
            });
        },

        handlePeriodoChange(ganador) {
            this.loadingAll = true;

            const data = {
                ganadores: [parseInt(ganador.id)], // Aseguramos que sea un array de integers
                nueva_asignacion: {
                    programa_id: parseInt(this.selectedProgram),
                    periodo_id: parseInt(ganador.periodo_id),
                    vigencia: parseInt(this.selectedYear)
                }
            };

            Api.Reconocimientos()
                .actualizarGanadores(this.token, JSON.stringify(data))
                .then((res) => res.json())
                .then((response) => {
                    if (!response.error) {
                        this.showAlert("Éxito", "Periodo actualizado correctamente", "success");
                        this.buscarGanadores(); // Refrescar la lista después de actualizar
                    } else {
                        throw new Error(response.error || "Error al actualizar el periodo");
                    }
                })
                .catch((error) => {
                    console.error('Error al actualizar ganador:', error);
                    this.showAlert("Error",
                        error.message || "No se pudo actualizar el periodo del ganador",
                        "error"
                    );
                    // Revertir el cambio en caso de error
                    ganador.periodo_id = this.selectedRecurrence;
                })
                .finally(() => {
                    this.loadingAll = false;
                });
        },

        handleProgramaMasivoChange() {
            const programa = this.programOptions.find(p => p.id === this.actualizacionMasiva.programa_id);
            if (programa && programa.recurrencia) {
                this.recurrenceOptionsMasivo = programa.recurrencia.periodos;
                this.actualizacionMasiva.periodo_id = null;
            }
        },

        getRecurrenceOptions(programaId) {
            const programa = this.programOptions.find(p => p.id === programaId);
            return programa && programa.recurrencia ? programa.recurrencia.periodos : [];
        },

        getProgramaNombre(id) {
            const programa = this.programOptions.find(p => p.id === id);
            return programa ? programa.nombre : '';
        },

        getPeriodoNombre(id) {
            const periodo = this.recurrenceOptions.find(p => p.id === id);
            return periodo ? periodo.nombre : '';
        },

        actualizarSeleccionados() {
            if (!this.isValidMasiveUpdate) return;
            this.dialogConfirmMasivo.show = true;
        },

        confirmarActualizacionMasiva() {
            this.loadingAll = true;
            this.dialogConfirmMasivo.show = false;

            const data = {
                ganadores: this.selectedGanadores.map(g => parseInt(g.id)),
                nueva_asignacion: {
                    programa_id: parseInt(this.actualizacionMasiva.programa_id),
                    periodo_id: parseInt(this.actualizacionMasiva.periodo_id),
                    vigencia: parseInt(this.actualizacionMasiva.vigencia)
                }
            };

            Api.Reconocimientos()
                .actualizarGanadores(this.token, JSON.stringify(data))
                .then((res) => res.json())
                .then((response) => {
                    if (!response.error) {
                        this.showAlert(
                            "Actualización completada",
                            `Se actualizaron ${this.selectedGanadores.length} ganadores correctamente`,
                            "success"
                        );
                        this.buscarGanadores();
                    } else {
                        const errorMessages = typeof response.error === 'object' 
                            ? Object.entries(response.error)
                                .map(([id, mensaje]) => `ID ${id}: ${mensaje}`)
                                .join('\n')
                            : response.error;
                            
                        this.showAlert(
                            "Error", 
                            `${response.message}\n\n${errorMessages}`.trim(),
                            "warning"
                        );
                    }
                })
                .catch((error) => {
                    console.error('Error en actualización masiva:', error);
                    this.showAlert(
                        "Error",
                        error.message || "Hubo un problema al actualizar los ganadores",
                        "error"
                    );
                })
                .finally(() => {
                    this.loadingAll = false;
                    this.selectedGanadores = [];
                });
        }
    },
    mounted() {
        this.getProgramas();
    }
};
</script>

<style lang="less" scoped>
.container-alert-form ::v-deep .v-alert__content {
    text-align: left;
}

.v-data-table {
    background-color: white;
}

.caption {
    font-size: 0.75rem;
}

.v-toolbar {
    border-bottom: 1px solid #e0e0e0;
}

.v-select.v-text-field--outlined.v-input--dense .v-select__selection--comma {
    margin-top: 8px;
}

.v-btn.small-icon-button {
    width: 24px;
    height: 24px;
    min-width: 24px;
}

.v-btn.small-icon-button .v-icon {
    font-size: 16px;
}
</style>